<div
  [ngClass]="{ square: type === 'square', circle: type === 'circle' }"
  [ngStyle]="{ width: imgWidth, height: imgHeight, overflow: 'hidden' }"
  [attr.alt]="alt"
>
  @if (src) {
  <img [imgPreloader]="src" />
  } @if (fullName) {
  <div class="initials">
    <span [ngStyle]="{ 'font-size': fontSize() }">
      {{ fullName | getInitials }}
    </span>
  </div>
  }
</div>
