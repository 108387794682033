import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { ImagePreloaderDirective } from '../../directives/image-preloader.directive';
import { InitialsFromNamePipe } from '../../pipes/initials-from-name.pipe';

const DEFAULT_SIZE = '40px';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  imports: [NgClass, NgStyle, ImagePreloaderDirective, InitialsFromNamePipe],
})
export class AvatarComponent implements OnChanges {
  @Input() src?: string;
  @Input() fullName?: string;
  @Input() width: string;
  @Input() height: string;
  @Input() alt?: string;
  @Input() type: 'square' | 'circle' = 'circle';

  imgWidth: string = DEFAULT_SIZE;
  imgHeight: string = DEFAULT_SIZE;

  constructor() {}

  fontSize() {
    return parseInt(this.height) / 2 + 'px';
  }
  ngOnChanges(): void {
    if (!this.width && !this.height) {
      this.imgWidth = DEFAULT_SIZE;
      this.imgHeight = DEFAULT_SIZE;
    }

    if (this.width && !this.height) {
      this.height = this.width;
    }

    if (this.height && !this.width) {
      this.width = this.height;
    }

    if (this.width && /^\d+$/.test(this.width)) {
      this.imgWidth = this.width + 'px';
    }

    if (this.height && /^\d+$/.test(this.height)) {
      this.imgHeight = this.height + 'px';
    }
  }
}
